<template>
  <section>
    <nav-bar></nav-bar>
    <side-navbar></side-navbar>
    <card-cookie>
      <div class="container-footer">
        <h1>Versions</h1>
        <h2>Client :</h2>
        <div class="div-v">
          <p>Version : {{ clientVersion }}</p>
          <p>Branche : master</p>
        </div>
        <br />
        <h2>Serveur :</h2>
        <div class="div-v">
          <p>Version : {{ urlVersion }}</p>
        </div>
      </div>
    </card-cookie>
  </section>
</template>

<script>
import CardCookie from '../components/CardCookie.vue';
import NavBar from '../components/NavBar.vue';
import SideNavbar from '../components/SideNavbar.vue';

export default {
  components: {
    NavBar,
    SideNavbar,
    CardCookie,
  },
  data() {
    return {
      clientVersion: process.env.VUE_APP_VERSION,
      urlVersion: null,
    };
  },
  methods: {
    getUrlVersion() {
      fetch(`${process.env.VUE_APP_API_URL}/versions`, {
        headers: new Headers({
          Authorization: this.token,
          'Content-type': 'application/json',
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.urlVersion = json.api_version;
        });
    },
  },
  created() {
    this.getUrlVersion();
  },
};
</script>

<style scoped>
.container-footer {
  font-size: 16px;
  width: 50%;
  margin: auto;
}

.card-cookie {
  width: 65%;
  margin-top: 160px;
}

h2 {
  width: 51%;
  margin: auto;
  text-align: left;
  margin-bottom: 10px;
}

.div-v {
  width: 28%;
  margin: auto;
  text-align: left;
}
</style>;
